<template>
  <div class="vuse-content-wrapper courses-formats" data-app>
    <v-card flat>
      <div class="filters">
        <help class="mx-2 help--crud" :helpId="43" />
        <h2 class="text-center align-text-center">Asignación de cursos</h2>
        <v-row justify="end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                dark
                class="mb-2"
                style="float: right"
                @click="handleCreate()"
                >Asignar
              </v-btn>
            </template>

            <span>Crear Curso</span>
          </v-tooltip>
        </v-row>
        <v-row justify="center" class="flex-column" no-gutters
          ><span class="">Formato seleccionado</span
          ><multiselect
            track-by="id"
            label="name"
            placeholder="Seleccionar formato"
            deselect-label="Remover"
          select-label="Seleccionar"
          selected-label="Seleccionado"
            v-model="selectedType"
            :allow-empty="false"
            :options="types"
            :loading="loadingTypes"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="false"
            class=""
            @input="onChangeType"
            style="max-width: 200px"
        /></v-row>
      </div>
      <v-data-table
        class="mt-3"
        :headers="headers"
        :items="courses"
        group-by="previous.name"
        hide-default-footer
        dense
      >
        <template v-slot:[`group.header`]="{ group }">
          <th colspan="2">
            {{ group }}
          </th>
        </template>
        <template v-slot:[`item.aproved`]="{ item }" style="width: 100px">
          <v-icon>{{ handlePrelationAproved(item) }}</v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }" style="width: 100px">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click.prevent="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
        <template v-slot:footer style="width: 100px">
          <v-pagination
            @input="handlePagination($event)"
            color="primary"
            v-model="page"
            :length="pages"
          />
        </template>
      </v-data-table>
    </v-card>
    <modal
      name="asignationFormatModal"
      :width="widthDialog"
      :max-width="600"
      :adaptive="true"
      :key="crudIndex"
    >
      <v-card class="pa-4" flat min-height="400px">
        <!-- <h2 class="text-center"></h2> -->
        <v-row no-gutters class="mt-4">
          <span>Cursos disponibles</span>
          <multiselect
            v-model="selectedCourses"
            label="name"
            track-by="id"
            placeholder="Seleccionar cursos"
            open-direction="bottom"
            :options="avaliableCourses"
            :multiple="true"
            :searchable="true"
            :loading="loadingAvaliableCourses"
            :internal-search="false"
            :clear-on-select="true"
            :close-on-select="true"
            :options-limit="300"
            :max-height="300"
            :show-no-results="false"
            :hide-selected="false"
            @search-change="getAvaliableCourses"
          />
          <v-row
            justify="center"
            no-gutters
            class=""
            style="
              position: absolute;
              bottom: 12px;
              left: 0;
              right: 0;
              width: fill-avaliable;
            "
          >
            <v-btn small color="primary" @click="createItem()" class="mr-2">
              Asignar
            </v-btn>
            <v-btn
              color="primary"
              dark
              @click="$modal.hide('asignationFormatModal')"
              small
            >
              Cancelar
            </v-btn>
          </v-row>
        </v-row>
      </v-card></modal
    >
  </div>
</template>

<script>
  export default {
    name: 'CourseFormats',
    created() {
      this.getTypes()
    },
    data() {
      return {
        typeIndex: 0,
        treeviewIndex: 100,
        open: [],
        active: [],
        types: [],
        selectedType: null,
        loadingTypes: false,
        courses: [],
        assignedCourses: [],
        loadingCourses: false,
        avaliableCourses: [],
        loadingAvaliableCourses: false,
        selectedCourses: null,
        crudIndex: 1,
        page: 1,
        pages: 1,
        itemsPerPage: 10,
        totalItems: 0,
        headers: [
          {
            text: 'Nombre del curso',
            align: 'left',
            value: 'name',
          },
          { text: 'Prelación aprobada', value: 'aproved', align: 'center' },
          { text: 'Acciones', value: 'actions', align: 'center' },
        ],
      }
    },
    props: {
      company: { type: Object, required: true },
    },
    computed: {
      widthDialog: {
        get() {
          return this.$vuetify.breakpoint.smAndDown ? '95%' : '60%'
        },
        set(newValue) {
          this.crudIndex++
        },
      },
    },
    mounted() {
      this.treeviewIndex++
    },
    methods: {
      onChangeType() {
        this.getCourses()
        this.getAvaliableCourses()
      },
      getTypes() {
        this.loadingTypes = true
        const withString = '&with[]=courses&children=2'
        this.$axios.get('types?limit=20' + withString).then((response) => {
          this.types = this.types.concat(response.data)
          this.selectedType = response.data.find((x) => x.id === this.company.type_id)
          this.loadingTypes = false
          this.onChangeType()
        })
      },
      getCourses() {
        this.loadingCourses = true
        const withString = '&with[]=previous'
        this.$axios
          .get(
            'courses?order=name&by=asc&limit=100&type_id=' +
              this.selectedType.id +
              withString
          )
          .then((response) => {
            this.courses = response.data
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.courses.forEach((course) => {
              if (!course.course_id) {
                course.previous = { name: 'No posee prelaciones' }
              }
            })
            this.loadingCourses = false
          })
      },
      getAvaliableCourses(search) {
        this.loadingAvaliableCourses = true
        let searchStr = ''
        if (search && search !== '') {
          searchStr = '&search=' + search
        }
        this.$axios
          .get(
            'courses?order=name&by=asc&limit=100&type_excluded=' +
              this.selectedType.id +
              searchStr
          )
          .then((response) => {
            this.avaliableCourses = response.data
            this.loadingAvaliableCourses = false
          })
      },
      handleCreate() {
        this.selectedCourses = null
        this.$modal.show('asignationFormatModal')
      },
      createItem() {
        const data = {}
        const courses = this.selectedCourses.map((x) => x.id)
        data.courses = courses
        this.$axios.patch('types/' + this.selectedType.id, data).then(() => {
          this.onChangeType()
          this.$modal.hide('asignationFormatModal')
        })
      },
      handlePrelationAproved(item) {
        if (!item.course_id) {
          return 'mdi-thumb-up'
        }
        const course = this.courses.find((course) => {
          return course.id === item.course_id
        })

        if (course === undefined) {
          return 'mdi-thumb-down'
        }
        return 'mdi-thumb-up'
      },
      handlePagination(page) {
        this.page = page
        this.getCourses()
      },
      async deleteItem(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text: 'Eliminarás esta información de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete('courses/' + item.id + '/detach-type/' + this.selectedType.id)
              .then(() => {
                this.page = 1
                this.getCourses()
              })
          }
        })
      },
    },
  }
</script>
