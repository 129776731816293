<template>
  <div class="vuse-content-wrapper courses-and-lessons">
    <v-container fluid>
      <smart-breadcrumbs :items="navigationElements" />
      <!-- Content -->

      <v-card>
        <v-toolbar color="primary" dark flat dense>
          <template v-slot:default>
            <v-tabs v-model="model" centered slider-color="teal">
              <v-tab href="#1"> Cursos </v-tab>
              <v-tab href="#2"> Asignación de Cursos </v-tab>
              <!-- <v-tab href="#tab-3"> Resultados de gestión </v-tab> -->
            </v-tabs>
          </template>
        </v-toolbar>

        <keep-alive>
          <v-tabs-items v-model="model" :key="model">
            <v-tab-item value="1">
              <courses :company="company" />
            </v-tab-item>
            <v-tab-item value="2">
              <!-- <movements :typeId="typeId" :key="typeIndex" /> -->
              <course-formats :company="company" />
            </v-tab-item>
          </v-tabs-items>
        </keep-alive>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import Courses from '@/views/Elearning/CRUDs/Courses'
  import CourseFormats from '@/components/Elearning/CourseFormats'
  export default {
    name: 'CoursesAndLessons',
    components: {
      Courses,
      CourseFormats,
    },
    data() {
      return {
        courses: [],
        model: this.$route.query.tap ? parseInt(this.$route.query.tap) : '1',
        navigationElements: [
          {
            text: 'Elearning',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/AdminPanel',
            href: '/business/elearning/admin-panel',
          },
          {
            text: 'Cursos',
            disabled: true,
          },
        ],
      }
    },
    created() {
      this.handleTab()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {
          this.typeId = newValue.type_id
          this.typeIndex++
        },
      },
    },
    methods: {
      handleTab() {
        const route = window.location.href.split('tab=')
        if (route.length > 1) {
          this.model = route[1]
        }
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .courses-and-lessons {
    .v-tabs-slider {
      margin-top: -5px;
    }
  }
</style>
