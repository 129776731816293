var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vuse-content-wrapper courses-formats",attrs:{"data-app":""}},[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"filters"},[_c('help',{staticClass:"mx-2 help--crud",attrs:{"helpId":43}}),_c('h2',{staticClass:"text-center align-text-center"},[_vm._v("Asignación de cursos")]),_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",staticStyle:{"float":"right"},attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.handleCreate()}}},'v-btn',attrs,false),on),[_vm._v("Asignar ")])]}}])},[_c('span',[_vm._v("Crear Curso")])])],1),_c('v-row',{staticClass:"flex-column",attrs:{"justify":"center","no-gutters":""}},[_c('span',{},[_vm._v("Formato seleccionado")]),_c('multiselect',{staticStyle:{"max-width":"200px"},attrs:{"track-by":"id","label":"name","placeholder":"Seleccionar formato","deselect-label":"Remover","select-label":"Seleccionar","selected-label":"Seleccionado","allow-empty":false,"options":_vm.types,"loading":_vm.loadingTypes,"multiple":false,"close-on-select":true,"clear-on-select":true,"preserve-search":false},on:{"input":_vm.onChangeType},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.courses,"group-by":"previous.name","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
return [_c('th',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(group)+" ")])]}},{key:"item.aproved",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.handlePrelationAproved(item)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"color":"primary","length":_vm.pages},on:{"input":function($event){return _vm.handlePagination($event)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,true)})],1),_c('modal',{key:_vm.crudIndex,attrs:{"name":"asignationFormatModal","width":_vm.widthDialog,"max-width":600,"adaptive":true}},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":"","min-height":"400px"}},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('span',[_vm._v("Cursos disponibles")]),_c('multiselect',{attrs:{"label":"name","track-by":"id","placeholder":"Seleccionar cursos","open-direction":"bottom","options":_vm.avaliableCourses,"multiple":true,"searchable":true,"loading":_vm.loadingAvaliableCourses,"internal-search":false,"clear-on-select":true,"close-on-select":true,"options-limit":300,"max-height":300,"show-no-results":false,"hide-selected":false},on:{"search-change":_vm.getAvaliableCourses},model:{value:(_vm.selectedCourses),callback:function ($$v) {_vm.selectedCourses=$$v},expression:"selectedCourses"}}),_c('v-row',{staticStyle:{"position":"absolute","bottom":"12px","left":"0","right":"0","width":"fill-avaliable"},attrs:{"justify":"center","no-gutters":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.createItem()}}},[_vm._v(" Asignar ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.$modal.hide('asignationFormatModal')}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }